import SectionHeader from '../components/sections/header';
import SectionUserNewsletter from '../components/sections/user-newsletter';
import SectionFooter from '../components/sections/footer';

import React, { useEffect } from 'react';
import { graphql, navigate } from 'gatsby';

import { IPage } from '../models/page.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { ISetting } from '../models/setting.model';

import { getNodes } from '../utils/get-nodes';
import { useUser } from '../hooks/use-user';
import getPageConfigFromSections from '../utils/get-page-config-from-sections';
import getSectionsWithRepeatIndex from '../utils/get-sections-with-repeat-index';
import getCustomStyleFromSetting from '../utils/get-custom-style-from-settings';

import MainLayout from '../layouts/main-layout';
import SectionFactory from '../components/hoc/section-factory';
import UpwardsButton from '../components/molecules/upwards-button';
import ThemeStyle from '../components/atoms/theme-style';

interface IIndexProps {
    readonly data: {
        page: IPage;
        loginPage: Pick<IPage, 'pathname'> | null;
        allThemeSetting: IQueryAllResult<ISetting>;
    };
}

const User: React.FC<IIndexProps> = ({ data }) => {
    const { page, loginPage, allThemeSetting } = data;
    const themeSettings = getNodes(allThemeSetting);
    const customStyle = getCustomStyleFromSetting(themeSettings);
    const userState = useUser();

    const { mainSectionIndex } = getPageConfigFromSections(page.sections);

    const sections = getSectionsWithRepeatIndex(page.sections);

    useEffect(() => {
        if (userState.isLoggedIn || !loginPage) return;
        navigate(loginPage.pathname);
    }, [loginPage, userState.isLoggedIn]);

    return (
        <MainLayout>
            <ThemeStyle style={customStyle} />
            {sections.map((section, index) => {
                return (
                    <SectionFactory
                        key={`section-${section.type}-${section.sectionId}`}
                        SectionComponent={sectionComponents[section.type]}
                        section={section}
                        TitleTag={mainSectionIndex === index ? 'h1' : 'h2'}
                    />
                );
            })}
            <UpwardsButton />
        </MainLayout>
    );
};

export const query = graphql`
    query ($pageId: Int!, $locale: String!) {
        loginPage: page(type: { eq: "login" }, locale: { eq: $locale }) {
            pathname
        }
        page(pageId: { eq: $pageId }, locale: { eq: $locale }) {
            sections {
                ...sectionFields
            }
        }
        allThemeSetting {
            edges {
                node {
                    name
                    value
                }
            }
        }
    }
`;

export default User;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';



const sectionComponents: Record<string, any> = {
    'header': SectionHeader,
    'user-newsletter': SectionUserNewsletter,
    'footer': SectionFooter,
};